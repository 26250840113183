@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@layer application {
  .row,
  .form-group {
    margin-right: -5px;
    margin-left: -5px;
  }
  .col-12 {
    width: 100%;
  }
  .col-11 {
    width: 91.66666667%;
  }
  .col-10 {
    width: 83.33333333%;
  }
  .col-9 {
    width: 75%;
  }
  .col-8 {
    width: 66.66666667%;
  }
  .col-7 {
    width: 58.33333333%;
  }
  .col-6 {
    width: 50%;
  }
  .col-5-5 {
    width: 45.83333335%;
  }
  .col-5 {
    width: 41.66666667%;
  }
  .col-4 {
    width: 33.33333333%;
  }
  .col-3 {
    width: 25%;
  }
  .col-2 {
    width: 16.66666667%;
  }
  .col-1 {
    width: 8.33333333%;
  }
  .col-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-offset-9 {
    margin-left: 75%;
  }
  .col-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-offset-6 {
    margin-left: 50%;
  }
  .col-offset-5-5 {
    margin-left: 45.83333335%;
  }
  .col-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-offset-3 {
    margin-left: 25%;
  }
  .col-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-5-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    float: left;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
  .ml-0 {
    margin-left: 0px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-5 {
    margin-left: 5px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mr-5 {
    margin-right: 5px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .pt-0 {
    padding-top: 0;
  }
  .p-5 {
    padding: 5px 5px;
  }
  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-15 {
    padding: 0 15px;
  }
  .w-400 {
    width: 400px;
  }
  .w-full {
    width: 100%;
  }
  .relative {
    position: relative !important;
  }
  .absolute {
    position: absolute;
  }
  .h-full {
    height: 100%;
  }
  .text-right {
    text-align: right !important;
  }
  .text-left {
    text-align: left !important;
  }
  .text-center {
    text-align: center !important;
  }
  .overflow-auto {
    overflow: auto;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .overflow-clip {
    text-overflow: clip;
  }
  .link {
    text-decoration: underline;
  }
  .link,
  .link:hover {
    color: #2d3236;
  }
  .link:hover {
    text-decoration: none;
  }
  .no-border {
    border-width: 0 !important;
  }
  .bold {
    font-weight: bold;
  }
  .italic {
    font-style: italic;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .hidden {
    display: none;
  }
  .flex {
    display: -webkit-lex;
    display: -ms-flexbox;
    display: flex;
  }
  .inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-lex;
    display: -ms-inline-flexox;
    display: inline-flex;
  }
  .flex-col {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-row {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-1 {
    -webkit-flex: 1 1 0%;
    -ms-flexbox: 1 1 0%;
    flex: 1 1 0%;
  }
  .flex-auto {
    -webkit-flex: 1 1 auto;
    -ms-flexbox: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-initial {
    -webkit-flex: 0 1 auto;
    -ms-flexbox: 0 1 auto;
    flex: 0 1 auto;
  }
  .flex-none {
    -webkit-flex: 0 0 auto;
    -ms-flexbox: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-grow {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex: 1;
    flex-grow: 1;
  }
  .flex-shrink {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex: 0;
    flex-shrink: 0;
  }
  .flex-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex: 0;
    flex-shrink: 0;
  }
  .flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .items-center {
    -webkit-box-align: center;
    -mox-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .items-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .items-baseline {
    -webkit-box-align: baseline;
    -mox-box-align: baseline;
    -ms-flex-align: baseline;
    -webkit-align-items: baseline;
    align-items: baseline;
  }
  .items-stretch {
    -webkit-box-align: stretch;
    -mox-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
  .justify-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-center {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .justify-between {
    -webkit-box-pack: justify;
    -font-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .justify-evenly {
    -webkit-box-pack: space-evenly;
    -moz-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
  }
  .btn {
    display: inline-block;
    line-height: 36px;
    padding: 0 15px;
    border-width: 0;
    border-radius: 2px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #fff;
    background-color: #009cca;
    transition: all 0.2s ease-in-out;
  }
  .btn:hover {
    color: #fff;
    background-color: #00a4d4;
  }
  .btn:hover:not(:disabled):not(.disabled) {
    opacity: 0.7;
  }
  .btn.loading {
    background: url("../images/loader.gif") 10px center no-repeat;
  }
  .btn.disabled {
    opacity: 0.3;
  }
  .btn-pill {
    border: 1px solid #009cca;
    border-radius: 15px;
    padding: 5px 15px;
  }
  .btn-icon-container {
    cursor: pointer;
    display: -webkit-lex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -mox-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .btn-icon-container.font-size-large .label-text {
    font-size: 14px;
  }
  .btn-icon-container.inlined {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-lex;
    display: -ms-inline-flexox;
    display: inline-flex;
  }
  .btn-icon-container[data-label-position="below"] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .btn-icon-container[data-label-position="below"] .label-text {
    margin-top: 5px;
  }
  .btn-icon-container[data-label-position="above"] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .btn-icon-container[data-label-position="above"] .label-text {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .btn-icon-container[data-label-position="above"] button {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .btn-icon-container[data-label-position="left"] button:before,
  .btn-icon-container[data-label-position="right"] button:before {
    vertical-align: baseline;
  }
  .btn-icon-container[data-label-position="left"] .label-text {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .btn-icon-container[data-label-position="left"] button {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .btn-icon-container[data-label-position="left"] button:before {
    margin-left: 12px;
  }
  .btn-icon-container[data-label-position="right"] button:before {
    margin-right: 12px;
  }
  .btn-icon-container .label-text {
    font-size: 10px;
  }
  .btn-icon-container:hover {
    color: #5b666e;
    background-color: transparent;
  }
  .btn-icon-container:hover .btn-icon {
    color: #5b666e;
  }
  .btn-icon-container.disabled {
    cursor: initial;
    color: #8e99a1;
  }
  .btn-icon-container .btn-icon {
    padding: 0;
    background-color: transparent;
    color: #2d3236;
    line-height: 20px;
  }
  .btn-icon-container .btn-icon.disabled {
    cursor: initial;
    color: #8e99a1;
  }
  .btn-icon-container .btn-icon.disabled:hover {
    color: #8e99a1;
  }
  .btn-alternative {
    background-color: #2caac4;
  }
  .btn-alternative:hover {
    color: #fff;
    background-color: #2eb1cc;
  }
  .btn-success {
    color: #fff !important;
    background-color: #00c0b3 !important;
  }
  .btn-success:hover {
    background-color: #00cabd !important;
  }
  .btn-opaque {
    color: #000;
    background-color: inherit;
  }
  .btn-edit,
  .btn-info {
    color: #fff !important;
    background-color: #69b6ff !important;
  }
  .btn-edit:hover,
  .btn-info:hover {
    background-color: #73bbff !important;
  }
  .btn-decline,
  .btn-danger {
    color: #fff !important;
    background-color: #f67c61 !important;
  }
  .btn-decline:hover,
  .btn-danger:hover {
    background-color: #f7846b !important;
  }
  .btn-primary {
    color: #30343f !important;
    background-color: #ddd !important;
    border: 1px solid #bbb;
  }
  .btn-primary:hover {
    background-color: #d8d8d8 !important;
  }
  .btn-apply {
    color: #fff !important;
    background-color: #009cca;
  }
  .btn-apply:hover {
    background-color: #00a4d4 !important;
  }
  .btn-apply-inverted {
    background: none;
    color: #009cca !important;
    border: 1px solid #009cca;
  }
  .btn-apply-inverted:hover {
    background: none;
    border: 1px solid #00a4d4 !important;
  }
  .btn-cancel {
    color: #fff !important;
    background-color: #9dbfcc;
  }
  .btn-cancel:hover {
    background-color: #a4c3cf !important;
  }
  .btn-send,
  .btn-confirm,
  .btn-submit {
    color: #fff !important;
    background-color: #00c0b3;
  }
  .btn-send:hover,
  .btn-confirm:hover,
  .btn-submit:hover {
    background-color: #00cabd !important;
  }
  .btn-export,
  .btn-import {
    color: #fff !important;
    background-color: #2caac4;
  }
  .btn-export:hover,
  .btn-import:hover {
    background-color: #2eb1cc !important;
  }
  .btn-refresh,
  .btn-switch {
    color: #fff !important;
    background-color: #69b6ff;
  }
  .btn-refresh:hover,
  .btn-switch:hover {
    background-color: #73bbff !important;
  }
  .btn-popup {
    text-align: center;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    background: #009cca;
  }
  .btn-popup:hover {
    background: #00a4d4 !important;
  }
  .btn-popup.new-footnote-button {
    width: 100%;
  }
  .btn-block {
    display: block !important;
    width: 100% !important;
  }
  .btn-round {
    padding: 5px 15px;
    border: 1px solid #009cca;
    border-radius: 15px;
  }
  .bg-transparent {
    background: transparent;
  }
  .buttons-react .btn.cancel {
    color: #fff !important;
    background-color: #9dbfcc;
  }
  .buttons-react .btn.cancel:hover {
    background-color: #a4c3cf !important;
  }
  .buttons-react .btn.btn-success {
    color: #fff !important;
    background-color: #009cca !important;
  }
  .buttons-react .btn.btn-success:hover {
    background-color: #00a4d4 !important;
  }
  .tab {
    width: 118px;
    line-height: 30px;
    margin: 0 1px;
    text-align: center;
    background-color: #3783a5;
    color: #fff;
    cursor: pointer;
    user-select: none;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: inset 0px -2px 3px 0px rgba(0,0,0,0.2);
  }
  .tab:first-child {
    margin: 0 1px 0 0;
  }
  .tab:last-child {
    margin: 0 0 0 1px;
  }
  .tab:hover {
    background-color: #3a89ad;
  }
  .tab--selected {
    box-shadow: none;
    background-color: #2caac4;
  }
  .tab--selected:hover {
    background-color: #2caac4;
  }
  .tab--attention {
    background-color: #f67c61 !important;
  }
  .tab--disabled {
    background-color: #d1d1d1 !important;
    cursor: default;
  }
  .tab--disabled:hover {
    background-color: #d1d1d1 !important;
  }
  .tabspane {
    border-bottom: 6px solid #2caac4;
  }
  .tabspane {
    display: -webkit-lex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 35px;
  }
  .tabspane .tabstext {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex: 1;
    flex-grow: 1;
    font-weight: bold;
    font-size: 16px;
    line-height: 35px;
  }
  .tabspane .tab {
    -webkit-flex: 0 0 118px;
    -ms-flexbox: 0 0 118px;
    flex: 0 0 118px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    font-size: 15px;
    font-size: 1.5rem;
  }
  .form .form-group {
    margin-bottom: 10px;
  }
  .form .form-group label {
    display: block;
    max-width: 100%;
    font-weight: 700;
    line-height: 20px;
  }
  .form-horizontal .form-group label {
    line-height: 36px;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 36px;
    padding: 0 10px !important;
    line-height: 34px;
    color: #555;
    background: #fff;
    border: 1px solid #ccc;
  }
  .clearfix,
  .row:before,
  .row:after,
  .form-horizontal .form-group:before,
  .form-horizontal .form-group:after {
    clear: both;
    display: table;
    content: " ";
  }
  .gdt-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .gdt-table > thead > tr,
  .gdt-table > tbody > tr {
    height: 40px;
    background-color: #f2f2f2;
    border: 2px solid #fff;
    box-sizing: border-box;
  }
  .gdt-table > thead > tr {
    background: #dedede;
  }
  .gdt-table > thead > tr th {
    font-weight: normal;
  }
  .gdt-table > tbody > tr:hover {
    background-color: #f7f7f7;
  }
  .gdt-table > tbody > tr > td {
    padding: 5px;
    border: 2px solid #fff;
    vertical-align: middle;
    text-align: left;
  }
  .gdt-table > thead > tr > th {
    padding: 5px;
    border: 2px solid #fff;
    vertical-align: middle;
    text-align: center;
  }
}
