@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.ep-modal.full-size.edms-insert-modal {
  min-width: 600px;
  width: calc(100vw - 300px);
  max-width: calc(100vw - 300px);
}
.ep-modal.full-size.edms-insert-modal .overlay {
  background: rgba(0,0,0,0.1);
  opacity: 1;
}
.ep-modal.full-size.edms-insert-modal .files {
  max-height: calc(75vh);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
  align-items: center;
  justify-items: flex-start;
  row-gap: 16px;
}
.ep-modal.full-size.edms-insert-modal .files .file {
  width: 100%;
  max-width: 130px;
  height: 150px;
  border: 2px solid #bfbfbf;
  border-radius: 3px;
}
.ep-modal.full-size.edms-insert-modal .files .file .pdf-icon {
  font-size: 48px;
}
.ep-modal.full-size.edms-insert-modal .files .file .pdf-icon:before {
  font-family: 'gdt';
  content: '\e105';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.ep-modal.full-size.edms-insert-modal .files .file .pdf-icon * {
  margin-left: 0.5rem;
}
.ep-modal.full-size.edms-insert-modal .files .file .file-icon {
  font-size: 48px;
}
.ep-modal.full-size.edms-insert-modal .files .file .file-icon:before {
  font-family: 'gdt';
  content: '\e098';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.ep-modal.full-size.edms-insert-modal .files .file .file-icon * {
  margin-left: 0.5rem;
}
.ep-modal.full-size.edms-insert-modal .files .file .icon,
.ep-modal.full-size.edms-insert-modal .files .file .miniature {
  padding: 10px;
  display: flex;
  flex-grow: 1;
  width: 120px;
  height: 120px;
  text-align: center;
  min-height: 75px;
  max-height: 150px;
  align-items: center;
  justify-content: center;
}
.ep-modal.full-size.edms-insert-modal .files .file .icon img,
.ep-modal.full-size.edms-insert-modal .files .file .miniature img {
  max-width: 100%;
  max-height: 100%;
}
.ep-modal.full-size.edms-insert-modal .files .file.selected {
  border: 2px solid #0089ba;
}
.ep-modal.full-size.edms-insert-modal .files .file .metadata {
  border-top: 1px solid #bfbfbf;
}
.ep-modal.full-size.edms-insert-modal .files .file .metadata .title {
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}
