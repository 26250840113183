@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.etd-insert-modal-legacy,
.etd-insert-modal {
  position: relative;
  width: 370px;
}
.etd-insert-modal-legacy h2,
.etd-insert-modal h2 {
  text-align: center;
}
.related-recommendations {
  margin-bottom: 20px;
}
.related-recommendations .btn-extraction,
.related-recommendations .btn-add {
  float: right;
}
.related-recommendations .btn-extraction {
  margin-right: 10px;
}
.related-recommendation {
  background-color: #f2f2f2;
}
.related-recommendation .uppercase {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 20px;
}
.related-recommendation__question {
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
}
.related-recommendation__question .caption {
  height: 40px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 10px;
}
.related-recommendation__question button:before {
  color: #000;
}
.related-recommendation__editor {
  margin: 10px;
  padding: 10px;
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 10px 3px #ccc;
  box-shadow: 0px 3px 10px 3px #ccc;
  background: #3783a5;
}
.related-recommendation__editor .public-DraftEditor-content {
  min-height: 50px;
  color: #000;
}
.related-recommendations-modal {
  width: 700px;
}
.related-recommendations-modal .selecting-questions {
  max-height: 400px;
  overflow: auto;
  margin-top: 20px;
}
.related-recommendations-modal .buttons-react {
  margin-top: 20px;
}
.related-recommendations-modal .buttons-react.not-wrapped {
  text-align: center;
}
.related-recommendations-modal .buttons-react.not-wrapped button {
  display: inline-block !important;
  width: auto !important;
  min-width: 150px;
  margin: 0px 10px;
}
.etd-insert-modal-legacy {
  width: 100%;
}
.insert-options {
  width: 320px;
  margin-bottom: 15px;
}
.insert-options label {
  display: block;
  padding: 5px 0px;
}
.insert-options label input#file {
  display: none;
}
.insert-options label input {
  position: relative;
  top: 3px;
}
.insert-options label button.file {
  line-height: 25px;
  margin-left: 10px;
  padding: 0 5px;
  white-space: initial;
}
.insert-options .outcomes-list-select {
  margin: 0px 10px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
}
.insert-options .outcomes-list-select input[type=checkbox] {
  float: left;
  top: 1px;
  margin-right: 5px;
}
.insert-options .outcomes-list-select .select-all {
  padding-left: 10px;
  line-height: 14px;
}
.insert-options .outcomes-list-select .separator {
  margin: 0px 0px 10px;
}
.insert-options .outcomes-list-select .select-item {
  padding: 0px 10px;
  line-height: 14px;
}
.insert-options .outcomes-list-select .select-item .outcome-name {
  display: block;
  overflow: hidden;
}
.insert-options .outcomes-list-select .select-item.selected {
  background: #f2f2f2;
}
.error {
  color: #f00;
  padding: 0 15px;
}
.hidden {
  visibility: hidden;
}
.content {
  text-align: left;
}
.spacer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 15px;
}
.content-mode-switch-modal {
  min-height: 146px;
  padding: 15px;
  top: 250px;
  left: 50%;
  margin-left: -260px;
  overflow: visible;
}
.content-mode-switch-modal .buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.content-mode-switch-modal .buttons button {
  width: 49%;
}
.content-mode-switch-modal p {
  margin-top: 0;
  margin-bottom: 5px;
}
.content-mode-switch-modal .modal-close {
  top: 0;
  right: 5px;
}
.recommendation-section-title h2 {
  text-align: left;
}
.recommendation-section-title .options-select-container {
  width: 127px;
  -webkit-flex: 0 0 127px;
  -ms-flexbox: 0 0 127px;
  flex: 0 0 127px;
}
.judgement-table {
  table-layout: auto !important;
}
.judgement-table td {
  border: none;
}
.judgement-table tbody.expanded {
  margin-bottom: 35px;
}
.assessment-section__header.assessment-setion__header--expanded {
  background: #005aa5;
}
.assessment-section__header.assessment-setion__header--expanded h2 {
  color: #fff;
}
.assessment-section__header .assessment-section__header__flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 20px;
}
.assessment-section__header td {
  z-index: 1;
  position: relative;
  padding: 10px 5px !important;
  border: 0px solid #fff !important;
  -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.5) !important;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5) !important;
}
.assessment-section__header h2 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 0;
  margin: 0;
}
.assessment-section__header .context-help-icon {
  color: #fff;
  font-size: 14px;
  position: relative;
  display: inline-block;
  margin-left: 5px;
  top: -5px;
}
.assessment-section__header .criterion {
  font-size: 14px;
  line-height: 20px;
}
.assessment-section__header .assessment-section__header__content {
  cursor: pointer;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.assessment-section__header.assessment-setion__header--collapsed {
  background: #f2f2f2;
  color: #2d3236;
}
.assessment-section__header.assessment-setion__header--collapsed .context-help-icon {
  color: #2d3236;
}
.assessment-section__header.assessment-setion__header--collapsed:hover {
  background: #005aa5;
  color: #fff;
}
.assessment-section__header.assessment-setion__header--collapsed:hover .context-help-icon {
  color: #fff;
}
.adolopment-section-separator {
  display: table;
  width: 100%;
  margin: 15px 0px;
}
.adolopment-section-separator td {
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
}
.adolopment-section-separator.assessment {
  background: #f2f2f2;
  line-height: 30px;
}
.adolopment-section-separator.assessment .adolopment-section-separator__color-marker {
  width: 20px;
}
.adolopment-section-separator.conclusions {
  line-height: 20px;
  font-size: 14px;
}
.adolopment-section-separator.conclusions .adolopment-section-separator__color-marker {
  width: 10px;
}
.adolopment-section-separator .adolopment-section-separator__section > div {
  padding-left: 5px;
}
.adolopment-section-separator .adolopment-section-separator__color-marker--original {
  background-color: #29abe2;
}
.adolopment-section-separator .adolopment-section-separator__color-marker--adolopment {
  background-color: #f7931e;
}
.conclusions-section-edit-dialog .conclusions-justification {
  border: none !important;
}
.conclusions-section-edit-dialog,
.assessment-section-edit-dialog {
  top: 15vh;
  width: 80vw !important;
  left: 10vw !important;
  padding: 25px 11px 11px;
}
.conclusions-section-edit-dialog h2,
.assessment-section-edit-dialog h2 {
  margin: 0 15px;
  text-align: left;
  font-size: 14px;
}
.conclusions-section-edit-dialog .section-details,
.assessment-section-edit-dialog .section-details {
  margin-bottom: 15px;
}
.conclusions-section-edit-dialog #controls-block,
.assessment-section-edit-dialog #controls-block {
  height: 35px;
  background-color: #f2f2f2;
  text-align: right;
  padding: 5px;
  vertical-align: middle;
}
.conclusions-section-edit-dialog .conclusions-data,
.assessment-section-edit-dialog .conclusions-data {
  width: 100%;
  margin-bottom: 11px;
  padding: 10px 5px;
  border: 1px solid #bfbfbf;
  position: relative;
}
.conclusions-section-edit-dialog .conclusions-data.highlighted,
.assessment-section-edit-dialog .conclusions-data.highlighted {
  border: 1px solid #f00;
  background: #fff0bb;
}
.conclusions-section-edit-dialog .gdt-editor-container,
.assessment-section-edit-dialog .gdt-editor-container {
  border: none;
}
.conclusions-section-edit-dialog .style-controls-container,
.assessment-section-edit-dialog .style-controls-container {
  top: calc(15vh + 137px);
  left: calc(10vw + 15px);
  position: fixed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.conclusions-section-edit-dialog .multiple-recommendations__single .gdt-editor-container,
.assessment-section-edit-dialog .multiple-recommendations__single .gdt-editor-container {
  border: 1px solid #bfbfbf;
}
.assessment-section-edit-dialog .assessment-data {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 5px;
  margin-bottom: 10px;
}
.assessment-section-edit-dialog .assessment-data.highlighted {
  border: 1px solid #f00;
  background: #fff0bb;
}
.assessment-section-edit-dialog .assessment-data .ep-card {
  margin: 5px;
}
.assessment-section-edit-dialog .assessment-data .judgement,
.assessment-section-edit-dialog .assessment-data .research-evidences,
.assessment-section-edit-dialog .assessment-data .additional-considerations {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  position: relative;
}
.assessment-section-edit-dialog .assessment-data .judgement .ep-card,
.assessment-section-edit-dialog .assessment-data .research-evidences .ep-card,
.assessment-section-edit-dialog .assessment-data .additional-considerations .ep-card {
  padding: 5px;
}
.assessment-section-edit-dialog .assessment-data .judgement .gdt-editor-container,
.assessment-section-edit-dialog .assessment-data .research-evidences .gdt-editor-container,
.assessment-section-edit-dialog .assessment-data .additional-considerations .gdt-editor-container {
  border: none;
}
.assessment-section-edit-dialog .assessment-data .judgement .public-DraftEditor-content,
.assessment-section-edit-dialog .assessment-data .research-evidences .public-DraftEditor-content,
.assessment-section-edit-dialog .assessment-data .additional-considerations .public-DraftEditor-content {
  height: 367px;
  overflow-y: auto;
}
.assessment-section-edit-dialog .assessment-data .research-evidences {
  width: calc(75% - 150px);
}
.assessment-section-edit-dialog .assessment-data .additional-considerations {
  width: 25%;
}
.recommendations-preview {
  padding: 10px;
  background-color: #fff;
}
.recommendations-preview .additional-considerations-panel {
  padding: 10px 0px;
  text-align: right;
}
.recommendations-preview .show-ac {
  display: inline;
}
.recommendations-preview .hide-ac {
  display: none;
}
.recommendations-preview .judgement-table col.additional-considerations,
.recommendations-preview .judgement-table display: none,
.recommendations-preview .judgement-table td.judgement {
  padding: 0 !important;
}
.recommendations-preview .judgement-table col.additional-considerations >div:nth-child(2),
.recommendations-preview .judgement-table display: none >div:nth-child(2),
.recommendations-preview .judgement-table td.judgement >div:nth-child(2) {
  padding: 5px;
}
.recommendations-preview.additional-considerations-visible .show-ac {
  display: none;
}
.recommendations-preview.additional-considerations-visible .hide-ac {
  display: inline;
}
#recommendations:-webkit-full-screen {
  z-index: 100 !important;
}
#recommendations,
#recommendations-preview {
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
  background: #fff;
}
#recommendations .preview-button,
#recommendations-preview .preview-button {
  text-align: right;
  margin: 5px;
}
#recommendations .recommendations-header,
#recommendations-preview .recommendations-header {
  background: #f2f2f2;
}
#recommendations .recommendations-header.expanded,
#recommendations-preview .recommendations-header.expanded {
  padding-bottom: 15px;
}
#recommendations .recommendations-header .title-container,
#recommendations-preview .recommendations-header .title-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#recommendations .recommendations-header h1.title,
#recommendations-preview .recommendations-header h1.title {
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  margin-left: 10px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  color: #232e51;
}
#recommendations .recommendations-header table,
#recommendations-preview .recommendations-header table {
  width: 100%;
  font-size: 14px;
  padding-bottom: 15px;
}
#recommendations .recommendations-header table .c-1,
#recommendations-preview .recommendations-header table .c-1 {
  width: 125px;
}
#recommendations .recommendations-header table .c-2,
#recommendations-preview .recommendations-header table .c-2 {
  width: auto;
  max-width: 38%;
}
#recommendations .recommendations-header table .right-button,
#recommendations-preview .recommendations-header table .right-button {
  width: 40px;
}
#recommendations .recommendations-header table tr td,
#recommendations-preview .recommendations-header table tr td {
  border: none;
  padding: 5px;
  display: table-cell;
  position: relative;
}
#recommendations .recommendations-header table tr td.question,
#recommendations-preview .recommendations-header table tr td.question {
  padding-left: 20px;
}
#recommendations .recommendations-header table tr td .gdt-editor-container,
#recommendations-preview .recommendations-header table tr td .gdt-editor-container,
#recommendations .recommendations-header table tr td .public-DraftEditor-content,
#recommendations-preview .recommendations-header table tr td .public-DraftEditor-content {
  border: none;
  min-height: 20px;
  padding: 0;
}
#recommendations .recommendations-header table tr td .edit-container,
#recommendations-preview .recommendations-header table tr td .edit-container {
  position: relative;
  background-color: #fff;
  border: 1px solid #cecece;
}
#recommendations .recommendations-header table tr td .edit-container.in-auto-mode,
#recommendations-preview .recommendations-header table tr td .edit-container.in-auto-mode {
  background-color: #e4e4e1;
  border: 1px solid #cecece;
  color: #808080;
}
#recommendations .recommendations-header table tr td .edit-container.with-switch,
#recommendations-preview .recommendations-header table tr td .edit-container.with-switch {
  padding-bottom: 30px;
}
#recommendations .recommendations-header table tr td .edit-container .gdt-editor-container,
#recommendations-preview .recommendations-header table tr td .edit-container .gdt-editor-container {
  height: 100%;
  color: #2d3236;
  border: none;
}
#recommendations .recommendations-header table tr td .edit-container .gdt-editor-container .public-DraftEditor-content,
#recommendations-preview .recommendations-header table tr td .edit-container .gdt-editor-container .public-DraftEditor-content {
  min-height: 100%;
  overflow: auto;
}
#recommendations .recommendations-header table tr td .edit-container textarea,
#recommendations-preview .recommendations-header table tr td .edit-container textarea {
  width: 100%;
  height: 100%;
  border: none;
}
#recommendations .recommendations-header table tr td .edit-container input,
#recommendations-preview .recommendations-header table tr td .edit-container input,
#recommendations .recommendations-header table tr td .edit-container .textarea-content,
#recommendations-preview .recommendations-header table tr td .edit-container .textarea-content {
  padding-left: 5px;
}
#recommendations .recommendations-header table tr td .auto-manual-switch,
#recommendations-preview .recommendations-header table tr td .auto-manual-switch {
  background: none;
  font-style: italic;
  color: #0058d0;
  position: absolute;
  line-height: initial;
  padding: 0;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}
#recommendations .recommendations-header table tr td .auto-manual-switch:hover,
#recommendations-preview .recommendations-header table tr td .auto-manual-switch:hover {
  background: none;
}
#recommendations .recommendations-header table tr td.label-cell,
#recommendations-preview .recommendations-header table tr td.label-cell {
  white-space: nowrap;
  padding-left: 20px;
}
#recommendations .recommendations-header table tr td.label-cell p,
#recommendations-preview .recommendations-header table tr td.label-cell p {
  margin: 0;
  display: inline-block;
  width: 100%;
  text-align: right;
  font-weight: bold;
}
#recommendations .recommendations-header table tr td.editable-cell p,
#recommendations-preview .recommendations-header table tr td.editable-cell p {
  margin: 0;
}
#recommendations .recommendations-header table tr td.wider,
#recommendations-preview .recommendations-header table tr td.wider {
  width: 165px;
}
#recommendations .recommendations-header table tr td span.outcomes-list,
#recommendations-preview .recommendations-header table tr td span.outcomes-list {
  min-width: 427px;
}
#recommendations .recommendations-header table tr td span.background-section,
#recommendations-preview .recommendations-header table tr td span.background-section {
  padding-right: 15px;
}
#recommendations .recommendations-header table tr td > div,
#recommendations-preview .recommendations-header table tr td > div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
#recommendations .recommendations-header table tr td > div p,
#recommendations-preview .recommendations-header table tr td > div p {
  margin: 0;
}
#recommendations .recommendations-header table tr td > div ul,
#recommendations-preview .recommendations-header table tr td > div ul {
  margin: 0;
}
#recommendations .recommendations-header table tr td > div.rows-1,
#recommendations-preview .recommendations-header table tr td > div.rows-1 {
  height: 32px;
}
#recommendations .recommendations-header table tr td > div.rows-2,
#recommendations-preview .recommendations-header table tr td > div.rows-2 {
  min-height: 54px;
  height: 1px;
}
#recommendations .recommendations-header table tr td > div.rows-4,
#recommendations-preview .recommendations-header table tr td > div.rows-4 {
  min-height: 98px;
  height: 1px;
}
#recommendations .recommendations-header table tr td > div.rows-max,
#recommendations-preview .recommendations-header table tr td > div.rows-max {
  height: 132px;
}
#recommendations .recommendations-header table tr td select,
#recommendations-preview .recommendations-header table tr td select {
  background-color: #fff;
}
#recommendations .recommendations-header table tr td input,
#recommendations-preview .recommendations-header table tr td input {
  width: 100%;
  padding: 0;
  font-size: 14px;
}
#recommendations .etd-section-title,
#recommendations-preview .etd-section-title {
  margin: 30px auto 0px;
  border-top: 2px solid #99afc6;
  padding: 30px 0px 50px;
  position: relative;
}
#recommendations .etd-section-title.with-tabs,
#recommendations-preview .etd-section-title.with-tabs {
  padding-bottom: 10px;
}
#recommendations .etd-section-title .breadcrumbs,
#recommendations-preview .etd-section-title .breadcrumbs {
  text-align: center;
  margin: 10px auto;
  background: #fff;
}
#recommendations .etd-section-title li.breadcrumbs-tab,
#recommendations-preview .etd-section-title li.breadcrumbs-tab {
  background-color: #fff;
}
#recommendations .etd-section-title li.selected.selected,
#recommendations-preview .etd-section-title li.selected.selected {
  font-weight: bold;
  background-color: #fff;
}
#recommendations .etd-section-title li.selected.selected span,
#recommendations-preview .etd-section-title li.selected.selected span {
  background-color: #fff;
}
#recommendations .etd-section-title li.selected.selected span:before,
#recommendations-preview .etd-section-title li.selected.selected span:before {
  border-left-color: #fff;
}
#recommendations .etd-section-title li a:after,
#recommendations-preview .etd-section-title li a:after,
#recommendations .etd-section-title li span:after,
#recommendations-preview .etd-section-title li span:after {
  border: 18px solid transparent;
  border-left: 18px solid #b3b3b3;
}
#recommendations .etd-section-title li a:before,
#recommendations-preview .etd-section-title li a:before,
#recommendations .etd-section-title li span:before,
#recommendations-preview .etd-section-title li span:before {
  border: 18px solid transparent;
  border-left: 12px solid #fff;
}
#recommendations .etd-section-title h1,
#recommendations-preview .etd-section-title h1 {
  text-align: center;
  font-size: 21px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 5px 0 0 10px;
}
#recommendations .etd-section-title ol,
#recommendations-preview .etd-section-title ol {
  margin-left: 0;
  display: block;
}
#recommendations .etd-section-title ol li,
#recommendations-preview .etd-section-title ol li {
  text-align: center;
}
#recommendations .etd-section-title ol li.disabled,
#recommendations-preview .etd-section-title ol li.disabled {
  cursor: default;
}
#recommendations .assessment-section,
#recommendations-preview .assessment-section {
  position: relative;
}
#recommendations .assessment-section .etd-section-title,
#recommendations-preview .assessment-section .etd-section-title {
  border-top: none;
  margin-top: 0;
}
#recommendations .assessment-section .gdt-editor-container,
#recommendations-preview .assessment-section .gdt-editor-container {
  border: none;
}
#recommendations .assessment-section .admin-comment .gdt-editor-container,
#recommendations-preview .assessment-section .admin-comment .gdt-editor-container {
  border: 1px solid #bfbfbf;
}
#recommendations .recommendation-section .recommendation-types,
#recommendations-preview .recommendation-section .recommendation-types {
  padding: 0 10px 0 5px;
}
#recommendations .conclusions-section .etd-card,
#recommendations-preview .conclusions-section .etd-card {
  margin: 30px 0px;
}
#recommendations .judgement-table,
#recommendations-preview .judgement-table {
  table-layout: fixed;
}
#recommendations .judgement-table colgroup col.blank,
#recommendations-preview .judgement-table colgroup col.blank {
  width: 20px;
}
#recommendations .judgement-table colgroup col.criteria,
#recommendations-preview .judgement-table colgroup col.criteria {
  width: 220px;
}
#recommendations .judgement-table colgroup col.judgements,
#recommendations-preview .judgement-table colgroup col.judgements {
  width: 150px;
}
#recommendations .judgement-table colgroup col.research-evidences,
#recommendations-preview .judgement-table colgroup col.research-evidences {
  width: auto;
}
#recommendations .judgement-table colgroup col.additional-considerations,
#recommendations-preview .judgement-table colgroup col.additional-considerations {
  width: 25%;
}
#recommendations .judgement-table colgroup col.edit-button-column,
#recommendations-preview .judgement-table colgroup col.edit-button-column {
  width: 35px;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.blank,
#recommendations-preview .judgement-table colgroup.diagnostic-initial col.blank {
  width: 20px;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.criteria,
#recommendations-preview .judgement-table colgroup.diagnostic-initial col.criteria {
  width: 17.5%;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.judgements,
#recommendations-preview .judgement-table colgroup.diagnostic-initial col.judgements {
  width: 27%;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.research-evidences,
#recommendations-preview .judgement-table colgroup.diagnostic-initial col.research-evidences {
  width: auto;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.additional-considerations,
#recommendations-preview .judgement-table colgroup.diagnostic-initial col.additional-considerations {
  width: 27%;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.edit-button-column,
#recommendations-preview .judgement-table colgroup.diagnostic-initial col.edit-button-column {
  width: 35px;
  border-right: 1px solid #bfbfbf;
}
#recommendations .judgement-table > thead,
#recommendations-preview .judgement-table > thead {
  background: #dedede;
  text-transform: uppercase;
}
#recommendations .judgement-table > thead tr,
#recommendations-preview .judgement-table > thead tr {
  height: 34px;
  line-height: 34px;
}
#recommendations .judgement-table > thead h1,
#recommendations-preview .judgement-table > thead h1 {
  padding-left: 20px;
  margin: 0;
  font-size: 20px;
  text-align: left;
  text-transform: none;
}
#recommendations .judgement-table > thead .headers th.blank,
#recommendations-preview .judgement-table > thead .headers th.blank,
#recommendations .judgement-table > thead .diagnostic-headers th.blank,
#recommendations-preview .judgement-table > thead .diagnostic-headers th.blank {
  border-right: none;
}
#recommendations .judgement-table > thead .headers th.research-evidences,
#recommendations-preview .judgement-table > thead .headers th.research-evidences,
#recommendations .judgement-table > thead .diagnostic-headers th.research-evidences,
#recommendations-preview .judgement-table > thead .diagnostic-headers th.research-evidences {
  width: auto;
}
#recommendations .judgement-table > thead .headers th.criteria,
#recommendations-preview .judgement-table > thead .headers th.criteria,
#recommendations .judgement-table > thead .diagnostic-headers th.criteria,
#recommendations-preview .judgement-table > thead .diagnostic-headers th.criteria {
  border-left: none;
}
#recommendations .judgement-table > thead .domain,
#recommendations-preview .judgement-table > thead .domain {
  height: 80px;
}
#recommendations .judgement-table > thead .domain span,
#recommendations-preview .judgement-table > thead .domain span {
  left: -0.5em;
}
#recommendations .judgement-table > tbody,
#recommendations-preview .judgement-table > tbody {
  display: block;
  width: 100%;
}
#recommendations .judgement-table tr.headers,
#recommendations-preview .judgement-table tr.headers {
  background: #dedede;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
}
#recommendations .judgement-table tr.headers td,
#recommendations-preview .judgement-table tr.headers td {
  position: relative;
}
#recommendations .judgement-table tr.headers td .header-cell-label,
#recommendations-preview .judgement-table tr.headers td .header-cell-label {
  position: relative;
}
#recommendations .judgement-table tr.headers td .header-cell-label .context-help-icon,
#recommendations-preview .judgement-table tr.headers td .header-cell-label .context-help-icon {
  position: absolute;
  top: 0;
  right: -20px;
}
#recommendations .judgement-table tr.headers td.blank,
#recommendations-preview .judgement-table tr.headers td.blank {
  border-right: none;
}
#recommendations .judgement-table tr.headers td.research-evidences,
#recommendations-preview .judgement-table tr.headers td.research-evidences {
  width: auto;
}
#recommendations .judgement-table tr.headers td.criteria,
#recommendations-preview .judgement-table tr.headers td.criteria {
  border-left: none;
}
#recommendations .judgement-table tbody > tr.assessment-section__header,
#recommendations-preview .judgement-table tbody > tr.assessment-section__header {
  display: table;
  width: 100%;
  table-layout: fixed;
}
#recommendations .judgement-table tbody > tr > td,
#recommendations-preview .judgement-table tbody > tr > td {
  padding: 5px;
  word-break: break-word;
}
#recommendations .judgement-table tbody > tr > td.domain-cell,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell,
#recommendations .judgement-table tbody > tr > td.judgement,
#recommendations-preview .judgement-table tbody > tr > td.judgement {
  vertical-align: top;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label,
#recommendations .judgement-table tbody > tr > td.judgement label,
#recommendations-preview .judgement-table tbody > tr > td.judgement label {
  display: block;
  line-height: 16px;
  margin-bottom: 12px;
  position: relative;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label input,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label input,
#recommendations .judgement-table tbody > tr > td.judgement label input,
#recommendations-preview .judgement-table tbody > tr > td.judgement label input,
#recommendations .judgement-table tbody > tr > td.domain-cell label span,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label span,
#recommendations .judgement-table tbody > tr > td.judgement label span,
#recommendations-preview .judgement-table tbody > tr > td.judgement label span {
  display: inline-block;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label input,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label input,
#recommendations .judgement-table tbody > tr > td.judgement label input,
#recommendations-preview .judgement-table tbody > tr > td.judgement label input,
#recommendations .judgement-table tbody > tr > td.domain-cell label span.checked-marker,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label span.checked-marker,
#recommendations .judgement-table tbody > tr > td.judgement label span.checked-marker,
#recommendations-preview .judgement-table tbody > tr > td.judgement label span.checked-marker {
  margin-left: 0;
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label span,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label span,
#recommendations .judgement-table tbody > tr > td.judgement label span,
#recommendations-preview .judgement-table tbody > tr > td.judgement label span {
  margin-left: 20px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label.varies input,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label.varies input,
#recommendations .judgement-table tbody > tr > td.judgement label.varies input,
#recommendations-preview .judgement-table tbody > tr > td.judgement label.varies input,
#recommendations .judgement-table tbody > tr > td.domain-cell label.varies span.checked-marker,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label.varies span.checked-marker,
#recommendations .judgement-table tbody > tr > td.judgement label.varies span.checked-marker,
#recommendations-preview .judgement-table tbody > tr > td.judgement label.varies span.checked-marker {
  margin-top: -4px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label.special,
#recommendations-preview .judgement-table tbody > tr > td.domain-cell label.special,
#recommendations .judgement-table tbody > tr > td.judgement label.special,
#recommendations-preview .judgement-table tbody > tr > td.judgement label.special {
  margin-top: 0.5rem;
  padding-top: 0.7rem;
  border-top: 1px dotted #bfbfbf;
}
#recommendations .judgement-table tbody > tr > td.recommendations-editor img,
#recommendations-preview .judgement-table tbody > tr > td.recommendations-editor img,
#recommendations .judgement-table tbody > tr > td.benefits-harms-table img,
#recommendations-preview .judgement-table tbody > tr > td.benefits-harms-table img {
  width: 100%;
}
#recommendations .judgement-table tbody > tr > td.recommendations-editor .editor-container .top-buttons,
#recommendations-preview .judgement-table tbody > tr > td.recommendations-editor .editor-container .top-buttons,
#recommendations .judgement-table tbody > tr > td.benefits-harms-table .editor-container .top-buttons,
#recommendations-preview .judgement-table tbody > tr > td.benefits-harms-table .editor-container .top-buttons {
  display: none;
}
#recommendations .judgement-table tbody > tr > td.recommendations-editor .editor-container.edit-content .top-buttons,
#recommendations-preview .judgement-table tbody > tr > td.recommendations-editor .editor-container.edit-content .top-buttons,
#recommendations .judgement-table tbody > tr > td.benefits-harms-table .editor-container.edit-content .top-buttons,
#recommendations-preview .judgement-table tbody > tr > td.benefits-harms-table .editor-container.edit-content .top-buttons {
  display: block !important;
}
#recommendations .judgement-table tbody > tr > td.criteria-cell,
#recommendations-preview .judgement-table tbody > tr > td.criteria-cell {
  position: relative;
  padding: 10px;
  padding-right: 20px;
  background: #d2dfed;
}
#recommendations .judgement-table tbody > tr > td.criteria-cell .context-help-icon,
#recommendations-preview .judgement-table tbody > tr > td.criteria-cell .context-help-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
#recommendations .judgement-table tbody > tr > td .criteria-description,
#recommendations-preview .judgement-table tbody > tr > td .criteria-description {
  padding: 5px;
  background: #d2dfed;
  margin-bottom: 10px;
}
#recommendations .judgement-table tbody > tr > td .criteria-description.edit-content button.insert,
#recommendations-preview .judgement-table tbody > tr > td .criteria-description.edit-content button.insert {
  display: block !important;
}
#recommendations .judgement-table tbody > tr > td .criteria-option-label,
#recommendations-preview .judgement-table tbody > tr > td .criteria-option-label {
  text-transform: lowercase;
}
#recommendations .judgement-table tbody > tr > td .criteria-option-label::first-letter,
#recommendations-preview .judgement-table tbody > tr > td .criteria-option-label::first-letter {
  text-transform: uppercase;
}
#recommendations .judgement-table tbody > tr > td div.question-title,
#recommendations-preview .judgement-table tbody > tr > td div.question-title {
  font-weight: bold;
  border-top: solid 2px #000;
}
#recommendations .judgement-table tbody > tr > td table.short thead,
#recommendations-preview .judgement-table tbody > tr > td table.short thead {
  border-bottom: solid 2px #000;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th {
  padding: 5px;
  background-color: #275c99;
  border-color: #000;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.effect-head-cell,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.effect-head-cell {
  background-color: #95b3d7;
  color: #000;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-1,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-1,
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-2,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-2,
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-3,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-3 {
  background-color: #b8cce4;
  font-weight: normal;
  color: #000;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row td.quality,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row td.quality,
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row td.no-of-participants-studies,
#recommendations-preview .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row td.no-of-participants-studies {
  border-left: 1px solid #000;
  text-align: center;
  background: none;
}
#recommendations .judgement-table tbody > tr > td table.short thead > tr,
#recommendations-preview .judgement-table tbody > tr > td table.short thead > tr {
  background-color: #fff;
}
#recommendations .judgement-table tbody > tr > td table.short thead > tr th,
#recommendations-preview .judgement-table tbody > tr > td table.short thead > tr th {
  padding: 5px;
  vertical-align: top;
  font-weight: bold;
}
#recommendations .judgement-table tbody > tr > td table.short tbody,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody {
  border-bottom: solid 2px #000;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td {
  text-align: left;
  vertical-align: top;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;
  border-left: none;
  border-right: none;
  word-wrap: break-word;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.diagnostic-outcome-label,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.diagnostic-outcome-label,
#recommendations .judgement-table tbody > tr > td table.short tbody td.diagnostic-outcome-label.active,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.diagnostic-outcome-label.active {
  vertical-align: middle;
  text-align: left;
  background: none !important;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.diagnostic-effect,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.diagnostic-effect {
  background-color: #dbe5f1;
  border-right: 1px solid #000;
  vertical-align: middle;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.complications-prevalences-desc,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.complications-prevalences-desc,
#recommendations .judgement-table tbody > tr > td table.short tbody td.inconclusive-prevalences-desc,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.inconclusive-prevalences-desc {
  background-color: #dbe5f1;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.control-label,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.control-label {
  background-color: #e0e0e0;
  border-color: #000;
  font-weight: bold;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.corresponding-risk,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.corresponding-risk {
  background-color: #ebebeb;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.risk-with-control,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.risk-with-control {
  background-color: none;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.risk-difference-with-intervention,
#recommendations-preview .judgement-table tbody > tr > td table.short tbody td.risk-difference-with-intervention {
  background-color: #ededed;
}
#recommendations .judgement-table tbody > tr > td table.short .printout,
#recommendations-preview .judgement-table tbody > tr > td table.short .printout {
  border-top: solid 2px #000;
  border-bottom: solid 2px #000;
  border-left: none;
  border-right: none;
}
#recommendations .judgement-table tbody > tr > td table.short .blue-cell,
#recommendations-preview .judgement-table tbody > tr > td table.short .blue-cell {
  text-align: left;
  color: #fff;
  font-weight: normal;
  background-color: #3271aa;
  border: 1px solid #efefef;
}
#recommendations .judgement-table tbody > tr > td table.short .grey-cell,
#recommendations-preview .judgement-table tbody > tr > td table.short .grey-cell {
  text-align: left;
  border-top: 1px solid #efefef;
  border-right: 1px solid #efefef;
  background-color: #e0e0e0;
}
#recommendations .judgement-table tbody > tr:nth-child(3) td:nth-child(4) table,
#recommendations-preview .judgement-table tbody > tr:nth-child(3) td:nth-child(4) table {
  margin: 35px 0;
}
#recommendations .judgement-table tbody > tr:nth-child(3) td:nth-child(4) table th:first-child,
#recommendations-preview .judgement-table tbody > tr:nth-child(3) td:nth-child(4) table th:first-child {
  width: 30%;
}
#recommendations .judgement-table tbody > tr label,
#recommendations-preview .judgement-table tbody > tr label {
  line-height: 1.8em;
}
#recommendations .judgement-table .test-accuracy-table-container table,
#recommendations-preview .judgement-table .test-accuracy-table-container table {
  width: auto;
}
#recommendations .judgement-table .test-accuracy-table-container table td,
#recommendations-preview .judgement-table .test-accuracy-table-container table td {
  border: 1px solid #bfbfbf;
}
#recommendations .judgement-table .test-accuracy-summary span,
#recommendations-preview .judgement-table .test-accuracy-summary span {
  display: block;
}
#recommendations .judgement-table table,
#recommendations-preview .judgement-table table {
  table-layout: fixed;
  margin-bottom: 10px;
  text-align: center;
}
#recommendations .judgement-table table th,
#recommendations-preview .judgement-table table th,
#recommendations .judgement-table table td,
#recommendations-preview .judgement-table table td {
  vertical-align: middle;
}
#recommendations .judgement-table .dx-inner-table thead,
#recommendations-preview .judgement-table .dx-inner-table thead {
  background-color: #d2dfed;
}
#recommendations .judgement-table .dx-inner-table td:first-child,
#recommendations-preview .judgement-table .dx-inner-table td:first-child {
  text-align: left;
}
#recommendations .judgement-table .dx-inner-table td,
#recommendations-preview .judgement-table .dx-inner-table td {
  font-size: 0.8em;
}
#recommendations .judgement-table .dx-inner-table .abs-difference-row,
#recommendations-preview .judgement-table .dx-inner-table .abs-difference-row {
  background-color: #e9e9e9;
}
#recommendations .judgement-table .dx-inner-table .quality-sign,
#recommendations-preview .judgement-table .dx-inner-table .quality-sign {
  font-size: 1.2em;
}
#recommendations .judgement-table table.confidence-judgement,
#recommendations-preview .judgement-table table.confidence-judgement {
  margin-top: 20px;
}
#recommendations .judgement-table table.confidence-judgement th:first-child,
#recommendations-preview .judgement-table table.confidence-judgement th:first-child {
  width: 30%;
}
#recommendations .judgement-table .judgement-table-asterisks,
#recommendations-preview .judgement-table .judgement-table-asterisks {
  margin: 5px 0 25px;
}
#recommendations .judgement-table .judgement label.varies,
#recommendations-preview .judgement-table .judgement label.varies {
  margin-top: 0.5rem;
  padding-top: 0.7rem;
  border-top: 1px dotted #bfbfbf;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance th:first-child,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-relative-importance th:first-child {
  width: 50%;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance th,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-relative-importance th,
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance td,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-relative-importance td {
  border: 1px solid #bfbfbf;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance td:nth-child(2) > input,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-relative-importance td:nth-child(2) > input {
  width: 40px;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof th:first-child,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-sof th:first-child {
  width: 35%;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof th,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-sof th,
#recommendations .judgement-table .benefits-harms table.research-evidence-sof td,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-sof td {
  border: 1px solid #bfbfbf;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof .denominator,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-sof .denominator {
  text-decoration: underline;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof .denominator:hover,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-sof .denominator:hover {
  background-color: #9bbde0;
  cursor: pointer;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof .choose-outcomes,
#recommendations-preview .judgement-table .benefits-harms table.research-evidence-sof .choose-outcomes {
  width: 100%;
  background-color: #9bbde0;
}
#recommendations .judgement-table table.yes-no-table,
#recommendations-preview .judgement-table table.yes-no-table {
  width: 50%;
}
#recommendations .judgement-table .printout,
#recommendations-preview .judgement-table .printout {
  background-color: #fff;
  text-align: left;
}
#recommendations .judgement-table table.outcomes-table.short,
#recommendations-preview .judgement-table table.outcomes-table.short {
  font-size: 0.8em;
}
#recommendations .judgement-table table.outcomes-table.short .content,
#recommendations-preview .judgement-table table.outcomes-table.short .content {
  min-height: inherit;
  background: inherit;
  border: none;
}
#recommendations .judgement-table table.outcomes-table.short td,
#recommendations-preview .judgement-table table.outcomes-table.short td {
  cursor: default !important;
}
#recommendations .judgement-table table.outcomes-table.short tr.outcome-diag-sof-row,
#recommendations-preview .judgement-table table.outcomes-table.short tr.outcome-diag-sof-row,
#recommendations .judgement-table table.outcomes-table.short .diagnostic-outcome-label,
#recommendations-preview .judgement-table table.outcomes-table.short .diagnostic-outcome-label {
  width: auto;
}
#recommendations .judgement-table table.outcomes-table.short .no-of-participants-studies,
#recommendations-preview .judgement-table table.outcomes-table.short .no-of-participants-studies {
  width: 15%;
}
#recommendations .judgement-table table.outcomes-table.short .quality,
#recommendations-preview .judgement-table table.outcomes-table.short .quality {
  width: 15%;
}
#recommendations .judgement-table table.outcomes-table.short .diagnostic-effect,
#recommendations-preview .judgement-table table.outcomes-table.short .diagnostic-effect {
  width: 8%;
}
#recommendations .judgement-table table.outcomes-table.short .comments,
#recommendations-preview .judgement-table table.outcomes-table.short .comments {
  width: 25%;
}
#recommendations .voting-title,
#recommendations-preview .voting-title {
  font-weight: bold;
}
#recommendations .voting-title .comment,
#recommendations-preview .voting-title .comment {
  float: right;
}
#recommendations .panel-voice-results hr,
#recommendations-preview .panel-voice-results hr {
  margin: 6px 0;
}
#recommendations .panel-voice-results td,
#recommendations-preview .panel-voice-results td {
  width: initial;
  position: relative;
}
#recommendations .panel-voice-results td.responses-details-cell,
#recommendations-preview .panel-voice-results td.responses-details-cell {
  padding-top: 35px;
  width: 100%;
}
#recommendations .panel-voice-results td.responses-details-cell .voting-details,
#recommendations-preview .panel-voice-results td.responses-details-cell .voting-details {
  height: 100%;
}
#recommendations .panel-voice-results td.responses-details-cell .voting-details .responses-details,
#recommendations-preview .panel-voice-results td.responses-details-cell .voting-details .responses-details {
  width: 100%;
}
#recommendations .panel-voice-results td.responses-details-cell .voting-details .responses-details .title,
#recommendations-preview .panel-voice-results td.responses-details-cell .voting-details .responses-details .title {
  text-align: left;
}
#recommendations .panel-voice-results td .ep-card,
#recommendations-preview .panel-voice-results td .ep-card {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#recommendations .panel-voice-results td .voting-details,
#recommendations-preview .panel-voice-results td .voting-details {
  padding: 0;
}
#recommendations .panel-voice-results td .voting-details .judgement-details,
#recommendations-preview .panel-voice-results td .voting-details .judgement-details {
  border-right: 0;
}
#recommendations .panel-voice-results .voting-judgment-cell,
#recommendations-preview .panel-voice-results .voting-judgment-cell {
  border: 1px solid #009cca;
  margin: -15px 15px -15px -15px;
  -webkit-flex-basis: 250px;
  flex-basis: 250px;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
#recommendations .panel-voice-results .voting-judgment-cell .voted-data,
#recommendations-preview .panel-voice-results .voting-judgment-cell .voted-data {
  margin: 0;
  padding: 10px;
  background: #009cca;
  color: #fff;
  width: 100%;
}
#recommendations .panel-voice-results .voting-comments-cell,
#recommendations-preview .panel-voice-results .voting-comments-cell {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
#recommendations .panel-voice-results .judgement-voting,
#recommendations-preview .panel-voice-results .judgement-voting {
  display: block;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  text-align: left;
}
#recommendations .panel-voice-results .judgement-voting .votes-summary,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary {
  padding: 5px;
}
#recommendations .panel-voice-results .judgement-voting .votes-summary > div,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary > div {
  position: relative;
  margin-bottom: 12px;
}
#recommendations .panel-voice-results .judgement-voting .votes-summary .vote-option-text,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary .vote-option-text,
#recommendations .panel-voice-results .judgement-voting .votes-summary .votes-ratio,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary .votes-ratio {
  display: inline-block;
  position: relative;
  z-index: 2;
}
#recommendations .panel-voice-results .judgement-voting .votes-summary .vote-option-text,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary .vote-option-text {
  max-width: 80%;
}
#recommendations .panel-voice-results .judgement-voting .votes-summary .votes-ratio,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary .votes-ratio {
  float: right;
}
#recommendations .panel-voice-results .judgement-voting .votes-summary .filler,
#recommendations-preview .panel-voice-results .judgement-voting .votes-summary .filler {
  background-color: #c9dac2;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  height: 100%;
  position: absolute;
  top: 0px;
}
#recommendations .panel-voice-results .voted-data,
#recommendations-preview .panel-voice-results .voted-data {
  text-align: center;
  width: 140px;
  margin-top: 10px;
}
#recommendations .panel-voice-results .hidden-info,
#recommendations-preview .panel-voice-results .hidden-info {
  font-style: italic;
  margin-bottom: 10px;
}
#recommendations .panel-voice-results,
#recommendations-preview .panel-voice-results {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#recommendations .panel-voice-results button,
#recommendations-preview .panel-voice-results button {
  float: right;
}
#recommendations .panel-voice-results button:before,
#recommendations-preview .panel-voice-results button:before {
  vertical-align: top;
}
#recommendations .question-text,
#recommendations-preview .question-text {
  padding-left: 10px;
  line-height: 36px;
  background-color: #dedede;
  border: 1px solid #bfbfbf;
  border-bottom: 0;
  text-align: left;
  font-weight: bold;
  font-size: 20px;
}
#recommendations .recommendation-table,
#recommendations-preview .recommendation-table,
#recommendations .recommendation-table-static,
#recommendations-preview .recommendation-table-static {
  border-collapse: collapse;
}
#recommendations .recommendation-table td,
#recommendations-preview .recommendation-table td,
#recommendations .recommendation-table-static td,
#recommendations-preview .recommendation-table-static td {
  padding: 0px 5px;
}
#recommendations .recommendation-table td h1,
#recommendations-preview .recommendation-table td h1,
#recommendations .recommendation-table-static td h1,
#recommendations-preview .recommendation-table-static td h1,
#recommendations .recommendation-table td h2,
#recommendations-preview .recommendation-table td h2,
#recommendations .recommendation-table-static td h2,
#recommendations-preview .recommendation-table-static td h2 {
  margin: 0px;
  padding: 0px;
}
#recommendations .recommendation-table td h1,
#recommendations-preview .recommendation-table td h1,
#recommendations .recommendation-table-static td h1,
#recommendations-preview .recommendation-table-static td h1 {
  font-size: 20px;
  font-size: 2rem;
}
#recommendations .recommendation-table td h2,
#recommendations-preview .recommendation-table td h2,
#recommendations .recommendation-table-static td h2,
#recommendations-preview .recommendation-table-static td h2 {
  font-size: 16px;
}
#recommendations .recommendation-table caption,
#recommendations-preview .recommendation-table caption,
#recommendations .recommendation-table-static caption,
#recommendations-preview .recommendation-table-static caption {
  border: 1px solid #bfbfbf;
  border-bottom: none;
  text-align: left;
  background-color: #dedede;
}
#recommendations .recommendation-table > tr > td:first-child,
#recommendations-preview .recommendation-table > tr > td:first-child,
#recommendations .recommendation-table-static > tr > td:first-child,
#recommendations-preview .recommendation-table-static > tr > td:first-child {
  text-align: left;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}
#recommendations .recommendation-table td:first-child,
#recommendations-preview .recommendation-table td:first-child {
  padding: 10px;
  background: #d2dfed;
}
#recommendations .recommendation-table-static td.align-left,
#recommendations-preview .recommendation-table-static td.align-left {
  text-align: left;
}
#recommendations .recommendation-table-static td.filled,
#recommendations-preview .recommendation-table-static td.filled {
  width: 240px;
  font-weight: bold;
  padding: 10px;
  background: #dedede;
}
#recommendations .recommendation-table-static td.final-judgement,
#recommendations-preview .recommendation-table-static td.final-judgement {
  width: 16%;
}
#recommendations .recommendation-table-static .small,
#recommendations-preview .recommendation-table-static .small {
  display: block;
  font-size: 0.65em;
}
#recommendations .recommendation-table-static .panel-voice-results td,
#recommendations-preview .recommendation-table-static .panel-voice-results td {
  padding: 5px;
}
#recommendations .recommendation-table-static .panel-voice-results td.responses-details-cell,
#recommendations-preview .recommendation-table-static .panel-voice-results td.responses-details-cell {
  padding-top: 35px;
}
#recommendations .recommendation-table-static .panel-voice-results .voting-details,
#recommendations-preview .recommendation-table-static .panel-voice-results .voting-details {
  margin: 0;
}
#recommendations .radios-table td,
#recommendations-preview .radios-table td {
  border: none;
  word-break: break-word;
  text-align: center;
}
#recommendations .radios-table td.varies,
#recommendations-preview .radios-table td.varies {
  border-left: #bfbfbf dotted 1px;
}
#recommendations .detailed-judgements-btn,
#recommendations-preview .detailed-judgements-btn {
  padding: 0;
}
#recommendations .conclusions-options,
#recommendations-preview .conclusions-options {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#recommendations .conclusions-options .ep-card,
#recommendations-preview .conclusions-options .ep-card {
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-basis: 100px;
  flex-basis: 100px;
}
#recommendations .conclusions-options .ep-card:not(:last-child),
#recommendations-preview .conclusions-options .ep-card:not(:last-child) {
  margin-right: 10px;
}
#recommendations .conclusions-options .ep-card.checked,
#recommendations-preview .conclusions-options .ep-card.checked {
  font-weight: bold;
  border: 1px solid #00ceb7;
  background: #e1fffc;
}
#recommendations .conclusions-options .ep-card.checked.readOnly,
#recommendations-preview .conclusions-options .ep-card.checked.readOnly {
  border: 1px solid #bfbfbf;
  background: #dfdfdf;
}
#recommendations .conclusions-options .ep-card.checked.changed,
#recommendations-preview .conclusions-options .ep-card.checked.changed {
  background: #fff0bb;
}
#recommendations .conclusions-options .ep-card label,
#recommendations-preview .conclusions-options .ep-card label {
  text-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
#recommendations .conclusions-options .ep-card input[type="radio"],
#recommendations-preview .conclusions-options .ep-card input[type="radio"] {
  display: block;
  margin: 5px auto;
}
#recommendations .conclusions-justification,
#recommendations-preview .conclusions-justification {
  border: 1px solid #bfbfbf;
  min-height: 150px;
  text-align: left;
  padding: 5px;
  margin-bottom: 20px;
}
#recommendations .conclusions-justification.readOnly,
#recommendations-preview .conclusions-justification.readOnly {
  background: #f2f2f2;
}
#recommendations .conclusions-justification.editable,
#recommendations-preview .conclusions-justification.editable {
  cursor: pointer;
}
#recommendations .conclusions-justification .detailed-justification .criterion-name,
#recommendations-preview .conclusions-justification .detailed-justification .criterion-name {
  font-style: italic;
  margin-bottom: 0;
}
#recommendations table.conclusions-voting-details,
#recommendations-preview table.conclusions-voting-details {
  width: 100%;
  table-layout: fixed;
}
#recommendations table.conclusions-voting-details td,
#recommendations-preview table.conclusions-voting-details td {
  text-align: left;
}
.voting-details .team-comments,
.voting-summary-dialog .team-comments {
  margin-bottom: 10px;
}
.voting-details .team-comments .comments-votes,
.voting-summary-dialog .team-comments .comments-votes {
  border-bottom: 1px solid #bfbfbf;
}
.voting-details .team-comments .comments-label,
.voting-summary-dialog .team-comments .comments-label {
  display: block;
  margin-top: 10px;
  text-transform: uppercase;
}
.voting-details div.comment,
.voting-summary-dialog div.comment {
  padding: 5px;
  border: 1px solid #bfbfbf;
  border-bottom: 0;
  background-color: #efefef;
  min-height: 49px;
}
.voting-details div.comment .show-comment,
.voting-summary-dialog div.comment .show-comment,
.voting-details div.comment .hide-comment,
.voting-summary-dialog div.comment .hide-comment {
  width: 80px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  float: right;
}
.voting-details div.comment .member-name,
.voting-summary-dialog div.comment .member-name {
  font-weight: bold;
}
.voting-details div.comment.empty,
.voting-summary-dialog div.comment.empty {
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 10px;
}
.voting-details div.comment p,
.voting-summary-dialog div.comment p {
  margin: 0;
}
.voting-details .admin-comment div[contenteditable],
.voting-summary-dialog .admin-comment div[contenteditable] {
  background-color: #fff;
}
.voting-details .admin-comment .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content,
.voting-summary-dialog .admin-comment .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content {
  min-height: 47px;
}
.voting-summary-modal {
  width: 68%;
  margin-top: 15%;
}
.voting-summary-modal h2 {
  text-align: center;
}
.voting-summary-modal .voting-summary-dialog .section-details,
.voting-summary-modal .voting-summary-dialog .draft-judgement-details {
  display: table-row;
}
.voting-summary-modal .voting-summary-dialog .section-details span,
.voting-summary-modal .voting-summary-dialog .draft-judgement-details span {
  display: table-cell;
}
.voting-summary-modal .voting-summary-dialog .section-details .draft-judgement,
.voting-summary-modal .voting-summary-dialog .draft-judgement-details .draft-judgement,
.voting-summary-modal .voting-summary-dialog .section-details .section-name,
.voting-summary-modal .voting-summary-dialog .draft-judgement-details .section-name {
  text-transform: uppercase;
}
.voting-summary-modal .voting-summary-dialog .section-details .details-value,
.voting-summary-modal .voting-summary-dialog .draft-judgement-details .details-value {
  padding-left: 15px;
  font-weight: bold;
}
.voting-summary-modal .voting-summary-dialog .vote-comments-title {
  margin-top: 15px;
}
.detailed-judgements {
  width: 70%;
}
.detailed-judgements .section-name {
  text-transform: uppercase;
}
.detailed-judgements .criterion-description {
  margin-left: 5px;
  font-weight: bold;
}
.detailed-judgements .additional-infos .additional-info {
  margin-top: 20px;
}
.detailed-judgements .panel-discussion {
  margin-top: 30px;
}
.detailed-judgements .inner-label {
  display: block;
  font-weight: bold;
  margin-top: 30px;
}
.detailed-judgements .discussion-content {
  height: 65px;
  overflow-y: scroll;
  border: 1px solid #bfbfbf;
  background-color: #fff;
}
.detailed-judgements .discussion-content p {
  margin: 1px;
}
.detailed-judgements .horizontal-radios {
  width: 100%;
  background-color: #fff;
  height: 65px;
}
.detailed-judgements .horizontal-radios td {
  text-align: center;
  vertical-align: middle;
}
.detailed-judgements .horizontal-radios td input[type=radio] {
  display: block;
  margin: 0.5em auto;
}
.detailed-judgements .horizontal-radios td label {
  display: block;
}
.detailed-judgements .horizontal-radios td.proposed {
  background: #f7bf9c;
}
.detailed-judgements .horizontal-radios .additional-options-border {
  border-left: 1px dashed #bfbfbf;
}
.detailed-judgements .bottom-buttons {
  margin-top: 20px;
}
.detailed-judgements .add-resource-btn {
  margin-top: 10px;
  line-height: 30px;
}
.detailed-judgements .judgements-table-container {
  margin-top: 20px;
}
.detailed-judgements .judgements-table-container .judgements-table {
  width: 100%;
}
.detailed-judgements .judgements-table-container .judgements-table .judgements-table-header > tr > th {
  font-weight: normal;
}
.detailed-judgements .judgements-table-container .judgements-table .judgement-table-row > th,
.detailed-judgements .judgements-table-container .judgements-table .judgement-table-row > td {
  border: 1px solid #bfbfbf;
}
.detailed-judgements .judgements-table-container .judgements-table .buttons-cell {
  width: 30px;
  vertical-align: middle;
  text-align: center;
}
.detailed-judgements .judgements-table-container .judgements-table .resource-name {
  width: 30%;
  height: 100%;
}
.detailed-judgements .judgements-table-container .judgements-table .resource-name .resource-name-editor {
  width: 100%;
  height: 100%;
  margin: 5px auto;
}
.detailed-justification-modal {
  overflow: visible;
  width: 680px !important;
}
.detailed-justification-modal .overall-description {
  padding-top: 10px;
  margin-bottom: 20px;
}
.detailed-justification-modal .overall-description .explanation {
  display: inline;
  margin-left: 5px;
  color: #657079;
}
.detailed-justification-modal .overall-description .gdt-editor-container {
  overflow-y: auto;
  background-color: #fff;
  height: 85px;
  border: 1px solid #bfbfbf;
}
.detailed-justification-modal .overall-description .gdt-editor-container .style-controls-container {
  position: fixed;
  top: 85px;
}
.detailed-justification-modal .detailed-justification .title {
  margin: 20px 0;
}
.detailed-justification-modal .detailed-justification .detailed-info {
  margin-bottom: 20px;
}
.detailed-justification-modal .detailed-justification .detail-editors {
  margin: 5px 0;
  height: 65px;
}
.detailed-justification-modal .detailed-justification .detail-editors .section-name {
  display: inline-block;
  width: 200px;
}
.detailed-justification-modal .detailed-justification .detail-editors .detailed-justification-content {
  overflow-y: auto;
  display: inline-block;
  float: right;
  background-color: #fff;
  width: 360px;
  height: 65px;
  border: 1px solid #bfbfbf;
  padding: 3px;
}
.detailed-justification-modal .detailed-justification .detail-editors .editor-buttons {
  float: right;
  border: 1px solid #bfbfbf;
  border-left: none;
  height: 65px;
  width: 25px;
  text-align: center;
}
.detailed-justification-modal .detailed-justification .detail-editors .editor-buttons button {
  vertical-align: middle;
}
.detailed-justification-modal .bottom-buttons {
  margin-top: 20px;
}
@media print {
  #recommendations th,
  #recommendations caption {
    -webkit-print-color-adjust: exact;
  }
  #recommendations .content {
    border: none;
  }
}
.opentip-container,
.medium-editor-toolbar,
.medium-editor-anchor-preview {
  z-index: 2147483647 !important;
}
#recommendations .panel-voice-etd .table-container {
  margin-top: 15px;
  position: relative;
}
#recommendations .panel-voice-etd .table-container .controls-container {
  position: absolute;
  right: 300px;
}
#recommendations .panel-voice-etd .table-container .controls-container .add-results {
  margin-right: 30px;
}
#recommendations .panel-voice-etd .table-container .controls-container .add-results:before {
  position: absolute;
  left: -30px;
  font-size: 18px;
}
#recommendations .panel-voice-etd .table-container .controls-container .eye:before {
  margin-right: 10px;
  vertical-align: baseline;
}
#recommendations .panel-voice-etd .table-container .controls-container .etd-view-options {
  padding: 5px 15px;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #009cca;
}
#recommendations .panel-voice-etd .table-container .controls-container .etd-view-options li {
  padding: 5px 0;
}
#recommendations .panel-voice-etd .table-container .controls-container .etd-view-options li input {
  margin-right: 5px;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-filters {
  position: absolute;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-filters > span {
  font-weight: bold;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-filters .options-select-container {
  height: 30px;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-filters .select-input {
  border: none;
  line-height: 20px;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-filters .select-input .text-box {
  line-height: 20px;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-filters .options-container {
  border: 1px solid #009cca;
}
#recommendations .panel-voice-etd .panel-voice-etd__history-tab {
  position: relative;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab {
  position: relative;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .edit-button-column,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .edit-button-column,
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .edit-button-cell,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .edit-button-cell {
  width: 35px;
  text-align: center;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .highlighted,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .highlighted {
  background-color: #fff0bb;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details {
  position: relative;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .options-select-container,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .options-select-container {
  min-width: 150px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .judgement-details__votes,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .judgement-details__votes {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .judgement-details__votes.comments-open,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .judgement-details__votes.comments-open {
  width: 50%;
  overflow: auto;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .judgement-details__votes.comments-closed,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .judgement-details__votes.comments-closed {
  width: 100%;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .overarching__pv-comments,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .overarching__pv-comments {
  background: #fff;
  border: 1px solid #bfbfbf;
  padding: 20px;
  position: absolute;
  top: 90px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: calc(50%);
  z-index: 5;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .overarching__pv-comments.open,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .overarching__pv-comments.open {
  right: 0px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .overarching__pv-comments.closed,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .overarching__pv-comments.closed {
  right: calc(-1 * 51vw);
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .overarching__pv-comments .responses-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .overarching__pv-comments .responses-details {
  display: block;
  width: 100%;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .overarching__pv-comments .responses-details .responses-group,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .overarching__pv-comments .responses-details .responses-group {
  max-height: 300px;
  overflow: auto;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .judgement-details .overarching__pv-comments .btn-icon-container,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .judgement-details .overarching__pv-comments .btn-icon-container {
  position: absolute;
  top: 7px;
  right: 7px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details {
  height: initial;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details > td,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details > td {
  padding: 0;
  width: 100%;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details,
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details {
  display: table-cell;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details {
  width: 280px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details.w-full,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details.w-full {
  width: 100%;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .voted-data,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .voted-data {
  width: 100%;
  color: #fff;
  background: #009cca;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .voted-data__options,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .voted-data__options {
  padding: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .draft-judgement,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .draft-judgement {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  text-align: left;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .draft-judgement .bold,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .draft-judgement .bold {
  font-weight: bold;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .votes-summary,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .votes-summary {
  margin-left: 15px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .voted-data,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .voted-data {
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 15px;
  text-align: center;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .voted-data > div,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .voted-data > div {
  display: inline-block;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .results-view-type-controls,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .results-view-type-controls {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .results-view-type-controls .btn-icon-container.inactive button:before,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .results-view-type-controls .btn-icon-container.inactive button:before {
  color: #d1d1d1;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .results-view-type-controls .btn-icon-container button,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .results-view-type-controls .btn-icon-container button {
  margin-left: 10px;
  line-height: 18px;
  font-size: 18px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container.selectable,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container.selectable {
  cursor: pointer;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container.active .votes-bar,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container.active .votes-bar {
  border: 1px solid #26c6da;
  background-color: #26c6da;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container .option-text,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container .option-text {
  color: #666;
  margin-bottom: 3px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container .votes-bar,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container .votes-bar {
  border: 1px solid #d1d1d1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container .votes-bar,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container .votes-bar,
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container .votes-count,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container .votes-count {
  display: inline-block;
  height: 20px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container .votes-bar,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container .votes-bar {
  background-color: #d1d1d1;
  text-align: center;
  margin-bottom: 17px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .judgement-details .vote-option-container .votes-count,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .judgement-details .vote-option-container .votes-count {
  width: 40px;
  vertical-align: top;
  text-align: center;
  color: #2d3236;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details {
  width: calc(100% - 280px);
  position: relative;
  padding: 10px 35px 10px 10px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .title,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .title {
  margin-top: 2px;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .responses-group,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .responses-group {
  margin-bottom: 10px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .responses-group .option-name,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .responses-group .option-name {
  margin-bottom: 5px;
  margin-left: 5px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .responses-group .response-container,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .responses-group .response-container {
  min-height: 40px;
  margin-bottom: 2px;
  padding: 5px;
  background: #f2f2f2;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .responses-group .response-container.with-comment .member-name,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .responses-group .response-container.with-comment .member-name {
  line-height: 30px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .admin-comment,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .admin-comment {
  margin-top: 20px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .admin-comment > span,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .admin-comment > span {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 5px;
}
#recommendations .panel-voice-etd .panel-voice-etd__workspace-tab .voting-details .responses-details .admin-comment > div,
#recommendations .panel-voice-etd .panel-voice-etd__history-tab .voting-details .responses-details .admin-comment > div {
  margin-top: 5px;
}
.panel-voice-etd__workspace-tab {
  overflow: hidden;
}
.conclusions-section {
  margin-right: 10px;
}
.conclusions-section .ep-card {
  margin: 30px 0px;
}
.conclusions-section .ep-card td.content > div {
  margin-bottom: 20px;
}
.conclusions-section .voting-details {
  margin-right: 10px;
  height: initial;
}
.conclusions-section .voting-details .voted-data {
  padding: 5px;
  text-align: left;
}
.conclusions-section .gdt-editor-container.readonly {
  padding: 5px;
  border: 1px solid #bfbfbf;
  background: #f2f2f2;
}
.type-of-recommendation-table-static td {
  border: none;
}
.type-of-recommendation-table-static .voting-details {
  margin-right: 10px;
}
.type-of-recommendation-table-static .ep-section-row.voting-title {
  margin-top: 35px;
}
.type-of-recommendation-table-static .panel-voice-results .ep-cell {
  padding: 5px;
}
.type-of-recommendation-table-static .panel-voice-results .ep-cell .voting-details {
  margin-right: 0;
}
.type-of-recommendation-table-static .panel-voice-results .responses-details-cell {
  padding: 5px;
  padding-top: 35px;
}
.type-of-recommendation-table-static .panel-voice-results .responses-details-cell .voting-details {
  margin-right: 5px;
}
.overall-justification .gdt-editor-container,
.overall-justification .gdt-editor-container.readonly {
  border: none;
  padding: 0;
  background: #fff;
}
.overall-justification .public-DraftEditor-content {
  min-height: auto;
}
.conclusions-justification.readOnly .overall-justification {
  cursor: normal;
}
.conclusions-justification.readOnly .overall-justification .gdt-editor-container,
.conclusions-justification.readOnly .overall-justification .gdt-editor-container.readonly {
  background: #f2f2f2;
}
.ep-card-with-etd-editor {
  overflow: initial;
}
.ep-card-with-etd-editor.readOnly {
  background: #f2f2f2;
}
.ep-card-with-etd-editor .ep-card-with-etd-editor__card-content {
  padding: 0 !important;
}
.ep-card {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  -webkit-box-shadow: 0px 3px 10px 3px #ccc;
  box-shadow: 0px 3px 10px 3px #ccc;
}
.ep-card.voting-details {
  border: 1px solid #009cca;
}
.ep-card .card__card-content {
  height: 100%;
  padding: 15px;
  background-color: transparent;
}
.ep-card.judgement__card {
  height: 100%;
  background: #ddeaf9;
}
.ep-card.judgement__card.readOnly {
  background: #f2f2f2;
}
.ep-section-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}
.ep-cell {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: box !important;
  display: flex !important;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ep-cell > h2 {
  text-transform: uppercase;
  margin-left: 18px;
}
.ep-cell.judgement {
  -webkit-flex-basis: 250px;
  flex-basis: 250px;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
.ep-cell.judgement .ep-card div {
  width: 100%;
}
.ep-cell.judgement.changed .ep-cell__content .ep-card.judgement__card {
  background: #fff0bb;
}
.ep-cell.research-evidences {
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex: 2;
  flex-grow: 2;
}
.ep-cell.additional-considerations {
  -webkit-flex-basis: 370px;
  flex-basis: 370px;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
#recommendations .old-recommendations > tbody {
  display: table-row-group;
}
#recommendations .old-recommendations tr td,
#recommendations .old-recommendations tr th {
  border: 1px solid #bfbfbf;
}
#recommendations .old-recommendations tbody > tr > td.rotated {
  background: #dedede;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-bottom: none;
}
#recommendations .old-recommendations tbody > tr > td.rotated span {
  display: block;
  position: absolute;
  white-space: nowrap;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  line-height: 22px;
  left: 0;
  top: 100%;
  text-align: center;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h85 {
  height: 85px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h85 span {
  width: 85px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h125 {
  height: 125px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h125 span {
  width: 125px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h130 {
  height: 130px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h130 span {
  width: 150px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h170 {
  height: 170px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h170 span {
  width: 170px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h180 {
  height: 180px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h180 span {
  width: 180px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h200 {
  height: 200px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h200 span {
  width: 200px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h270 {
  height: 270px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h270 span {
  width: 270px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h360 {
  height: 360px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h360 span {
  width: 360px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h400 {
  height: 400px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h400 span {
  width: 400px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h490 {
  height: 490px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h490 span {
  width: 490px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h710 {
  height: 710px;
}
#recommendations .old-recommendations tbody > tr > td.rotated.h710 span {
  width: 710px;
}
#recommendations .old-recommendations tbody > tr > td.confidence-benefits-harms {
  height: 160px;
}
#recommendations .old-recommendations tbody > tr > td.values-and-preferences {
  height: 180px;
}
#recommendations .old-recommendations tbody > tr > td.diagnostic-test-accuracy {
  height: 200px;
}
#recommendations .old-recommendations tbody > tr > td.values {
  height: 50px;
}
#recommendations .old-recommendations tbody > tr > td.resources {
  height: 90px;
}
#recommendations .old-recommendations tbody > tr > td.acceptability {
  height: 125px;
}
#recommendations .old-recommendations tbody > tr > td.feasibility {
  height: 125px;
}
#recommendations .old-recommendations tbody > tr > td.radio-input {
  text-align: center;
}
#recommendations .old-recommendations tbody > tr > td spacer {
  height: 20px;
  background-color: #dedede;
  border: 1px solid #bfbfbf;
  border-top: none;
}
#recommendations .old-recommendations tbody > tr > td spacer td {
  border: none;
}
#recommendations .old-recommendations th.rotated {
  padding: 0;
  position: relative;
  overflow: hidden;
  border-bottom: none;
}
#recommendations .old-recommendations th.rotated span {
  display: block;
  position: absolute;
  white-space: nowrap;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  line-height: 22px;
  left: 0;
  top: 100%;
  text-align: center;
}
#recommendations .old-recommendations th.rotated.h85 {
  height: 85px;
}
#recommendations .old-recommendations th.rotated.h85 span {
  width: 85px;
}
#recommendations .old-recommendations th.rotated.h125 {
  height: 125px;
}
#recommendations .old-recommendations th.rotated.h125 span {
  width: 125px;
}
#recommendations .old-recommendations th.rotated.h130 {
  height: 130px;
}
#recommendations .old-recommendations th.rotated.h130 span {
  width: 150px;
}
#recommendations .old-recommendations th.rotated.h170 {
  height: 170px;
}
#recommendations .old-recommendations th.rotated.h170 span {
  width: 170px;
}
#recommendations .old-recommendations th.rotated.h180 {
  height: 180px;
}
#recommendations .old-recommendations th.rotated.h180 span {
  width: 180px;
}
#recommendations .old-recommendations th.rotated.h200 {
  height: 200px;
}
#recommendations .old-recommendations th.rotated.h200 span {
  width: 200px;
}
#recommendations .old-recommendations th.rotated.h270 {
  height: 270px;
}
#recommendations .old-recommendations th.rotated.h270 span {
  width: 270px;
}
#recommendations .old-recommendations th.rotated.h360 {
  height: 360px;
}
#recommendations .old-recommendations th.rotated.h360 span {
  width: 360px;
}
#recommendations .old-recommendations th.rotated.h400 {
  height: 400px;
}
#recommendations .old-recommendations th.rotated.h400 span {
  width: 400px;
}
#recommendations .old-recommendations th.rotated.h490 {
  height: 490px;
}
#recommendations .old-recommendations th.rotated.h490 span {
  width: 490px;
}
#recommendations .old-recommendations th.rotated.h710 {
  height: 710px;
}
#recommendations .old-recommendations th.rotated.h710 span {
  width: 710px;
}
.etd-with-voting .recommendation-types {
  margin: 0 10px 20px;
}
.etd-with-voting .voting-judgment-cell {
  width: 100%;
}
.etd-with-voting .voting-details__judgement {
  -webkit-flex-basis: 250px;
  flex-basis: 250px;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
.etd-with-voting .voting-details__judgement .votes-summary div {
  position: relative;
}
.etd-with-voting .voting-details__judgement .votes-summary .vote-option-text {
  display: inline-block;
  padding-right: 40px;
}
.etd-with-voting .voting-details__judgement .votes-summary .votes-ratio {
  position: absolute;
  right: 0px;
  top: 0px;
}
.etd-with-voting .voting-details__comments {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.etd-with-voting .voting-details__comments .card__card-content {
  width: 100%;
  height: 100%;
}
.etd-with-voting .voting-details__comments .voting-comments-cell {
  width: 100%;
}
.conclusions-section .voting-details .ep-card {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  border: 1px solid #009cca;
}
.conclusions-section .panel-voice-results .voting-details {
  margin-left: -24px;
  margin-bottom: -24px;
  margin-right: -24px;
}
.ep-cell__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.ep-cell__content > div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.ep-expand-button {
  margin-top: 10px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.ep-expand-button svg {
  width: 100%;
  height: 100%;
  fill: #ffa745;
}
.assessment-toolbar {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  right: 10px;
  top: 50px;
  position: absolute;
}
.table-view-options {
  margin-right: 10px;
}
.table-view-options .collapse-options:before,
.table-view-options .expand-options:before {
  content: "";
}
.table-view-options .collapse-options:after,
.table-view-options .expand-options:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.table-view-options .collapse-options:before {
  content: "";
}
.table-view-options .collapse-options:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e071';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.table-view-options .collapse-options:after,
.table-view-options .expand-options:after {
  margin-left: 10px;
  color: #3783a5;
}
.table-view-options__container {
  padding: 15px;
  background: #fff;
  border: 1px solid #009cca;
}
.table-view-options__container h4 {
  margin: 0 10px;
}
.table-view-options__container .adolopment-options {
  margin-top: 20px;
}
.type_of_recommendation__adolopment-switcher {
  position: relative;
  top: -10px;
}
.conclusions__adolopment-switcher,
.conclusions_rec_just__adolopment-switcher,
.type_of_recommendation__adolopment-switcher {
  text-align: right;
  margin-right: 20px;
}
.conclusions__adolopment-switcher,
.conclusions_rec_just__adolopment-switcher {
  position: relative;
  top: 22px;
}
.multiple-recommendations__single {
  margin-top: 15px;
}
.multiple-recommendations__single .single__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 10px;
}
.multiple-recommendations__single .single__header .header__select-options {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.multiple-recommendations__single .single__header .header__select-options .options-select-container {
  min-width: 400px;
  max-width: 400px;
}
.multiple-recommendations__single .single__header .header__select-options .ep-select-with-checkboxes__trigger {
  border: 1px solid #bfbfbf;
  height: 35px;
  min-width: 400px;
  max-width: 400px;
}
.multiple-recommendations__single .single__header .header__select-options .ep-select-with-checkboxes__trigger .ep-expand-button {
  margin: 0;
  padding: 0 23px 0 0;
}
.multiple-recommendations__single .single__header .header__select-options .ep-select-with-checkboxes__trigger .ep-expand-button svg {
  display: none;
}
.multiple-recommendations__single .single__header .header__select-options .ep-select-with-checkboxes__trigger .ep-expand-button:before {
  content: '▼';
  font-size: 13px;
  color: $color-gray;
  margin-right: 5px;
}
.multiple-recommendations__single .single__header .header__select-options .ep-select-with-checkboxes__trigger .trigger__label {
  text-align: left;
}
.recommendation-extraction-form-modal .button-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.recommendation-extraction-form-modal .button-row button {
  width: 50%;
}
.ranking-modal-content .ranking-modal-content__description .gdt-rating-element {
  display: inline-block;
  position: relative;
  top: 2px;
  padding: 1px 5px;
  width: 15px;
  height: 15px;
}
table.mulitintervention-type-of-recommendation,
table.interventions-rating {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 20px;
}
table.mulitintervention-type-of-recommendation.desirableEffects .trivial,
table.interventions-rating.desirableEffects .trivial {
  background: #e5ffe2;
}
table.mulitintervention-type-of-recommendation.desirableEffects .trivial:hover,
table.interventions-rating.desirableEffects .trivial:hover {
  background: #e8ffe5;
}
table.mulitintervention-type-of-recommendation.desirableEffects .small,
table.interventions-rating.desirableEffects .small {
  background: #c4f1bf;
}
table.mulitintervention-type-of-recommendation.desirableEffects .small:hover,
table.interventions-rating.desirableEffects .small:hover {
  background: #caf2c5;
}
table.mulitintervention-type-of-recommendation.desirableEffects .moderate,
table.interventions-rating.desirableEffects .moderate {
  background: #93e88b;
}
table.mulitintervention-type-of-recommendation.desirableEffects .moderate:hover,
table.interventions-rating.desirableEffects .moderate:hover {
  background: #9eea97;
}
table.mulitintervention-type-of-recommendation.desirableEffects .large,
table.interventions-rating.desirableEffects .large {
  background: #5dc953;
}
table.mulitintervention-type-of-recommendation.desirableEffects .large:hover,
table.interventions-rating.desirableEffects .large:hover {
  background: #6dce64;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .trivial,
table.interventions-rating.undesirableEffects .trivial {
  background: #ffdfd8;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .trivial:hover,
table.interventions-rating.undesirableEffects .trivial:hover {
  background: #ffe2dc;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .small,
table.interventions-rating.undesirableEffects .small {
  background: #fec8bc;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .small:hover,
table.interventions-rating.undesirableEffects .small:hover {
  background: #fecec3;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .moderate,
table.interventions-rating.undesirableEffects .moderate {
  background: #ffaa98;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .moderate:hover,
table.interventions-rating.undesirableEffects .moderate:hover {
  background: #ffb3a2;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .large,
table.interventions-rating.undesirableEffects .large {
  background: #ff896f;
}
table.mulitintervention-type-of-recommendation.undesirableEffects .large:hover,
table.interventions-rating.undesirableEffects .large:hover {
  background: #ff957d;
}
table.mulitintervention-type-of-recommendation tr td,
table.interventions-rating tr td {
  text-align: center;
}
table.mulitintervention-type-of-recommendation tr td,
table.interventions-rating tr td,
table.mulitintervention-type-of-recommendation tr th,
table.interventions-rating tr th {
  font-weight: normal;
  border: 2px solid #fff;
  padding: 10px;
  background-color: #f2f2f2;
  vertical-align: middle;
  color: #000;
}
table.mulitintervention-type-of-recommendation tr td.blank,
table.interventions-rating tr td.blank,
table.mulitintervention-type-of-recommendation tr th.blank,
table.interventions-rating tr th.blank {
  background: transparent;
}
table.mulitintervention-type-of-recommendation tr td.bold,
table.interventions-rating tr td.bold,
table.mulitintervention-type-of-recommendation tr th.bold,
table.interventions-rating tr th.bold {
  font-weight: bold;
}
table.mulitintervention-type-of-recommendation tr td.blue-cell,
table.interventions-rating tr td.blue-cell,
table.mulitintervention-type-of-recommendation tr th.blue-cell,
table.interventions-rating tr th.blue-cell {
  color: #fff;
  max-width: 200px;
  background-color: #275c74;
}
table.mulitintervention-type-of-recommendation tr td.dark-gray-cell,
table.interventions-rating tr td.dark-gray-cell,
table.mulitintervention-type-of-recommendation tr th.dark-gray-cell,
table.interventions-rating tr th.dark-gray-cell {
  background-color: #dedede;
}
table.mulitintervention-type-of-recommendation tr td.gray-cell,
table.interventions-rating tr td.gray-cell,
table.mulitintervention-type-of-recommendation tr th.gray-cell,
table.interventions-rating tr th.gray-cell {
  max-width: 100px;
}
table.mulitintervention-type-of-recommendation tr td.option-mark,
table.interventions-rating tr td.option-mark,
table.mulitintervention-type-of-recommendation tr th.option-mark,
table.interventions-rating tr th.option-mark {
  cursor: pointer;
}
table.mulitintervention-type-of-recommendation tr td.option-mark:hover,
table.interventions-rating tr td.option-mark:hover,
table.mulitintervention-type-of-recommendation tr th.option-mark:hover,
table.interventions-rating tr th.option-mark:hover {
  background-color: #e6e6e6;
}
table.mulitintervention-type-of-recommendation tr .options-select-container,
table.interventions-rating tr .options-select-container {
  border: none;
  width: 100%;
  text-align: center;
}
table.mulitintervention-type-of-recommendation tr .options-select-container .select-value,
table.interventions-rating tr .options-select-container .select-value {
  text-transform: uppercase;
  font-weight: bold;
}
table.mulitintervention-type-of-recommendation tr .options-select-container .select-input,
table.interventions-rating tr .options-select-container .select-input {
  border: none;
  padding: 0;
}
.mdg-recommendation-section {
  position: relative;
  margin-bottom: 40px;
}
.mdg-recommendation-section .extraction-form {
  margin-left: -20px;
  max-width: 620px;
}
.mdg-recommendation-section .extraction-form .extraction-field__name {
  padding-left: 5px;
  text-align: left;
  width: 160px;
}
table.overarching-results {
  width: 100%;
  min-width: 1000px;
}
table.overarching-results tr,
table.overarching-results td {
  background: #fff;
  border: 3px solid #fff;
}
table.overarching-results thead td {
  cursor: pointer;
}
table.overarching-results thead td.uncertain,
table.overarching-results thead td.no_included_studies,
table.overarching-results thead td.varies,
table.overarching-results thead td.dont_know {
  width: 100px !important;
}
table.overarching-results thead td.tag {
  width: 200px !important;
}
table.overarching-results thead td.option {
  font-weight: bold;
  padding: 5px 10px;
  background: #e6e6e6;
  vertical-align: bottom !important;
  text-align: left;
}
table.overarching-results thead td.option.additional {
  background: #cecece;
}
table.overarching-results tbody tr td {
  padding: 0px !important;
}
table.overarching-results tbody tr td.tag {
  text-align: right;
  padding: 10px !important;
  background: #f2f2f2;
}
table.overarching-results tbody tr td.tag .tag-name {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
table.overarching-results tbody tr td.active .votes_count {
  background: #ffa745;
}
table.overarching-results tbody tr td .votes_count {
  width: 100%;
  padding: 10px;
  border: 2px solid #f2f2f2;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
