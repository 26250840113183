@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#visual-guidelines {
  font-size: 14px;
}
#visual-guidelines .add-link {
  color: #0b6dad;
  font-style: italic;
  cursor: pointer;
}
#visual-guidelines .chapters-list .chapters-list-items .chapter-item .caption-row.chapter-caption {
  border-bottom: none !important;
}
#visual-guidelines .chapters-list .chapters-list-items .chapter-item .caption-row.chapter-caption h2.chapter-header-text {
  color: #000 !important;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}
#visual-guidelines .chapters-list .chapters-list-items .chapter-item .chapter-content {
  border-bottom: none !important;
}
#visual-guidelines .general-information-form .extraction-field__value.multiText {
  margin-bottom: 50px;
}
#visual-guidelines .document-sections-title.acp_recommendations_populations {
  margin-bottom: 30px;
}
#visual-guidelines .document-sections-title.acp_recommendations_populations .title-text {
  display: inline-block;
  color: #0b6dad;
  font-size: 1.3em;
}
#visual-guidelines .chapter-section-management .status-content {
  display: none;
}
#visual-guidelines .epiditor--container {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
#visual-guidelines .epiditor--container .epiditor--pm-editor-root.ProseMirror {
  min-height: 25px;
}
#visual-guidelines .epiditor--container .epiditor--pm-editor-root.ProseMirror p {
  margin: 5px 0px;
}
#visual-guidelines .epiditor--container .epiditor--pm-editor-root.ProseMirror[contenteditable="true"] {
  min-height: 150px;
}
#visual-guidelines .recommendation-edit .epiditor--pm-editor-root.ProseMirror,
#visual-guidelines .recommendations-list .epiditor--pm-editor-root.ProseMirror {
  background: none;
}
#visual-guidelines .recommendation-edit .epiditor--pm-editor-root.ProseMirror p,
#visual-guidelines .recommendations-list .epiditor--pm-editor-root.ProseMirror p {
  margin: 5px 0px;
}
#visual-guidelines .recommendation-item .additional-options .ep-select-with-checkboxes__trigger {
  border: 1px solid #bfbfbf;
  height: 35px;
  width: 100%;
}
#visual-guidelines .recommendation-item .additional-options .ep-select-with-checkboxes__trigger .ep-expand-button {
  margin: 0;
  padding: 0 23px 0 0;
}
#visual-guidelines .recommendation-item .additional-options .ep-select-with-checkboxes__trigger .ep-expand-button svg {
  display: none;
}
#visual-guidelines .recommendation-item .additional-options .ep-select-with-checkboxes__trigger .ep-expand-button:before {
  content: '▼';
  font-size: 13px;
  color: $color-gray;
  margin-right: 5px;
}
#visual-guidelines-container.printout * {
  overflow: visible;
}
